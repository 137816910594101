<template>
  <k-crud-layout>
    <template #header>
      {{ $tc("microservice.title", 2) }}
    </template>
    <template #view.list>
      <Resource
        :form-component="() => import('../components/forms/ConnectorForm.vue')"
        :index-request="indexHandler"
        :meta="{
          name: $tc('microservice.title', 1),
          namePlural: $tc('microservice.title', 2),
        }"
        :model-type="modelType"
        :show-request="showHandler"
        :table-content="tableContent"
        :can-add="false"
        :can-delete="false"
        :can-update="false"
        @row-click="openService"
      />
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { autocomplete } from '@/api/endpoints/microservice';
import { show } from '@/api/endpoints/connector.js';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import Microservice from '@/application/models/microservice.js';
import { mapGetters } from "vuex";

export default {
  name: 'MicroserviceResource',
  mixins: [querySyncMixin],
  components: {
    KCrudLayout,
    Resource,
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    modelType: () => Microservice,
    tableContent() {
      return [
        {
          text: this.$t('microservice.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        }
      ]
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'connector.index' },
        text: this.$tc('microservice.title', 2),
      },
    ]);
  },
  methods: {
    openService(microserviceId) {
      this.$router.push({ name: 'connector.microservice', params: { microserviceId } });
    },
    showRequest: show,
    indexHandler: autocomplete
  }
}
</script>
